.package-card-container {
  border: 1px solid #e1e8ed;
  border-bottom-width: 3px;
  padding-left: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 40%;
  min-width: 300px;
  margin-top: 30px;
}

.package-title {
  font-family: "Poppins", sans-serif;
  font-weight: 5500;
  font-size: 13px;
  line-height: 25px;
  color: #000;
  letter-spacing: 0.2px;
}

.package-lesson {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 25px;
  color: #808080;
  letter-spacing: 0.2px;
}
