.completion {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 20px;
  width: 45%;
  min-width: 300px;

  &__title,
  &__sub-title {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    color: #000;
  }

  &__sub-title {
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__progress {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 20px 0 50px;

    &-circle {
      position: relative;
      width: 100px;
      height: 100px;
    }
  }

  &__accuracy {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #127fbe;
    text-align: center;
  }

  &__details {
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-text {
      font-family: "Poppins", sans-serif;
      font-size: 14px;
    }

    &-time {
      font-weight: 600;
      font-size: 14px;
    }
  }

  &__learnt {
    border: 1px solid #e1e8ed;
    padding: 15px 10px;
    margin-bottom: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    &-text {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 14px;
      color: #000;
    }
  }

  &__modal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    height: 100px;
    padding: 0 16px;

    &-btn {
      width: 85%;
      text-align: center;
      background-color: #169fed;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      border: 2px solid #1d628aff;
      border-bottom-width: 4px;
      border-radius: 20px;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 15px;
      color: #fff;
      letter-spacing: 0.2px;
    }
  }
}
