.activity_card {
  max-width: 600px;
  margin-top:10px;

  .top {
    background: #b7e1f9;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 10px;

    img {
      margin: auto;
      display: block;
      max-width: 260px;
      height: auto;
    }
  }

  .bottom {
    padding: 24px;
    background-color: #1177b2;
    color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    h1 {
      text-align: center;
      font-size: 18px;
    }
    h2 {
      text-align: center;
      font-size: 16px;
    }
    p {
      text-align: center;
      font-size: 14px;
    }

    button {
      max-width: 400px;
      margin: 20px auto 0 auto;
      height: 64px;
    }
  }
}
