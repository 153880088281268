.game-loading-container {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 60%;
  max-width: 600px;
}

.title {
  font-size: 20px;
  line-height: 45px;
  color: #169fed;
  letter-spacing: 0.2px;
}

.desc {
  font-size: 15px;
  line-height: 19px;
  color: #000;
  letter-spacing: 0.2px;
  margin-top: 10px;
}

.loading-container {
  position: absolute;
  bottom: 10%;
}

.loading-text {
  color: #b3b3b3;
  font-size: 15px;
}
