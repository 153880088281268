body,
main,
html {
  overflow-x: hidden;
}



.react-calendar .react-calendar__navigation__arrow {
  display: none !important;
}

    .react-calendar__tile:disabled {
      background-color: white !important;
      
    }

    .react-calendar__tile:disabled abbr{
      color: #ababab !important;
    }

.react-calendar .react-calendar__tile--active {
  background-color: white !important;
   border-radius: 15px;
  border: 2px solid #169fed !important;
  padding: 0px !important ;
}

.react-calendar__tile{
  height: 50px;
  width: 50px;
  padding: 0;
   border-radius: 15px;
}
