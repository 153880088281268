*{

  .contBtn {
    display: flex;
    justify-content: right;
    width: 100%;
  }

 

  .calBtn {
    align-content: right;
    padding: 10px 1.5rem !important;
    border-radius: 25px !important;
    color: white;
    background-color: #169fed;
    border: 1px solid #169fed;
    width:auto !important ;
    font-size: 16px !important;
  }

    .disabled{
        background-color: grey;
        border: 1px solid grey;
  }

  .avatar{
        height: 70px;
        width: 70px;
        border-radius: 100%;
        background-color: #E1E8ED;
    }
}



.stepsbox{
    width: 40%;
    display: flex;
    flex-direction: column;
    gap:1rem;

      .calClose {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }

    .calendar {
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;



  .calSpace {
    border: 2px solid #e1e8ed;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
  }

  .selects {
    padding: 1rem;
    border-radius: 10px;
    outline: none;
    width:100%;
  }

  .contBtn {
    display: flex;
    justify-content: right;
    width: 100%;
  }

 

  .calBtn *{
    align-content: right;
    padding: 10px 1.5rem;
    border-radius: 25px;
    color: white;
    background-color: #169fed;
    border: 1px solid #169fed;
  }

    .disabled{
        background-color: grey;
        border: 1px solid grey;
  }
}

.box2{
    margin-top: 30px;
    width: 100%;
    
    .pBox{
        display: flex ;
        flex-direction: column ;
        gap: 2rem;
        margin:2rem 0px;

        .partners{
            display: flex;
            align-items: center;
            gap: 10px;
            background-color: white;
            border: 1px solid #E1E8ED;
            box-shadow: 0px 3px 1px #E1E8ED;
            padding: 15px;
            border-radius: 10px;


            .avatar{
                height: 70px;
                width: 70px;
                border-radius: 100%;
                background-color: #E1E8ED;
            }

            .funcs{
                display: flex ;
                align-items: center;
                gap: 10px;
            }

            .profil{
                border: none;
                background-color: white;
                color: #169fed;
                text-decoration: underline;
                cursor:pointer;
                z-index: 10000000000000000;
            }
        }
    }
    .partners:hover, .partners:active{
        border: 1px solid #169fed;
        box-shadow: 0px 3px 1px #169fed;
        transition: 1s;
    }


}

.extend{
    margin:2rem 0px;
    width: 100%;
    border: 1px solid #E1E8ED;
    box-shadow: 0px 3px 1px #E1E8ED;
    padding: 2rem;
    border-radius:15px ;

    h4{
        color: grey;
        margin: 2rem 0rem;
    }

   .extendBox{
    display: flex;
    align-items: center;
    gap: 1rem;
     .funcs{
        display: flex ;
        align-items: center;
        gap: 10px;
    }

     
   }

    .surm{
        margin: 10px 0px;
        color: grey;
    }


           
}

.comtools{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 0rem;

    h4{
        color: grey;
    }

    .comtoolsBox{
        display: flex ;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
        border: 1px solid #E1E8ED;
        box-shadow: 0px 3px 1px #E1E8ED;
        border-radius: 10px ;
        cursor: pointer;
    }

    .comtoolsBox:hover, .comtools:active{
           border: 1px solid #169fed;
            box-shadow: 0px 3px 1px #169fed;
            transition: 1s;
    }
}

.confirm{
    border: 2px solid #E1E8ED;
    border-radius: 15px;
    margin: 1rem 0rem;

    .langType{
        padding: 2rem;
        border-bottom: 1px solid #E1E8ED;
        line-height: 2rem;
    }

    .avsect{
        display: flex;
        align-items: center;
        padding: 2rem;
        gap:10px;

        p{
            font-size: 18px;
            font-weight: bold;
        }
    }
    .sessect{
        padding: 2rem;
        border-top: 2px solid #E1E8ED;

        .sesHead{
            display:flex;
            align-items: center;
            justify-content: space-between;

            button{
                background-color: transparent;
                border: none;
            }
        }

        .sesbody{
            margin-top: 2rem ;
            font-weight: 500;
            display: flex;
            gap: 10px;
            align-items: flex-end;

            .sesdate{
                font-size:18px ;
            }

            .sestime{
                color: grey;
            }
        }
        

    }
}


}


@media (max-width:1024px){
    .stepsbox{
        width: 100%;
        margin: 2rem 0px;
    }
}


