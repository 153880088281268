.brief {
  background-color: #fff;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 20px;
    line-height: 25px;
    color: #000;
    letter-spacing: 0.2px;
    margin-bottom: 25px;
  }

  &__package-title {
    font-size: 14px;
    line-height: 25px;
    color: #000;
    letter-spacing: 0.2px;
  }

  &__lesson-exercise {
    font-size: 11px;
    line-height: 28px;
    color: #808080;
    letter-spacing: 0.2px;
  }

  &__goal {
    font-size: 14px;
    line-height: 23px;
    color: #000;
    letter-spacing: 0.2px;
    padding: 10px 0;
  }

  &__base-text {
    font-size: 14px;
    line-height: 23px;
    color: #808080;
    letter-spacing: 0.2px;
    padding: 10px 0;
  }

  &__con {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 8px;
    border: 2px solid #e1e8ed;
    padding: 20px 10px;
    margin: 20px 0;
    border-radius: 8px;
    width: 45%;
    min-width: 300px;
  }

  &__point {
    font-size: 15px;
    line-height: 23px;
    color: #000;
    letter-spacing: 0.2px;
    padding: 10px 0;
    width: 80%;
  }

  &__button-container {
    background-color: white;
    height: 60px;
    padding: 0 16px;
  }

  &__button {
    width: 43%;
    min-width: 270px;
    text-align: center;
    background-color: #169fed;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    border: 2px solid #1d628aff;
    border-bottom-width: 4px;
    border-radius: 20px;

    font-size: 15px;
    color: #fff;
    letter-spacing: 0.2px;
  }
}
