.activity-list-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 30px;
  border-radius: 12px;
  border: 2px solid #e1e8ed;
  background-color: #fff;
  cursor: pointer;

  .activity-list-card-content {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .activity-list-card-icon {
    width: 40px;
    height: 40px;
  }

  .activity-list-card-details {
    display: flex;
    flex-direction: column;
  }

  .activity-list-card-title {
    font-size: 16px;
    font-weight: 500;
  }

  .activity-list-card-lessons {
    font-size: 14px;
    color: #808080;
    font-weight: 500;
  }

  .activity-list-card-chevron {
    color: #808080;
  }
}
