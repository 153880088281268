.patnerCard {
  border: 2px solid #e1e8ed;
  border-radius: 12px;
  padding: 2rem;
}

.heading {
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  line-height: 3rem;
  letter-spacing: 0%;
}
.sub_heading {
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0%;
  color: #808080;
  margin-bottom: 40px;
}
.slots_available {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.info{
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0px ;
}
