.lesson-list {
  padding: 20px 0;
  max-width: 600px;

  .lesson-list-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
    color:#808080;
  }

  .lesson-card {
    background-color: #fff;
    padding: 16px 30px;
    border-radius: 12px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 12px;
    flex-direction: column;

    .lesson-card-title {
      font-size: 16px;
      font-weight: 500;
    }

    .lesson-card-subtitle {
      font-size: 14px;
      color: #808080;
    }
  }
}
