.dashboard-interactive-card {
  background: #169fed;
  padding: 28px 16px;
  border-radius: 12px;
  background-image: url("./pattern-frame.svg");
  background-size: cover; /* Ensures the image covers the entire container */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  background-position: center;
  color: #ffffff;
  display: grid;
}
.item h3,
.item p {
  margin: 0;
}
.grid-container {
  display: grid;
  width: 510px;
  height: 272px;
  grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
  grid-template-rows: repeat(3, 1fr); /* 3 equal rows */
  gap: 10px; /* Space between items */
  background-color: #f3f3f3; /* Background for the grid container */
  background: #169fed;
  padding: 28px 90px;
  border-radius: 12px;
}

.item:nth-child(1) {
  grid-column: 1 / 2; /* Occupies column 1 */
  grid-row: 1 / 3; /* Occupies rows 1 and 2 */
  width: 112px;
  height: 112px;
  border-radius: 50%;
  font-size: 82px;
  font-weight: 800;
  background-color: #ffffff; /* Coral */
  color: #169fed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.item:nth-child(2) {
  grid-column: 2 / 4; /* Occupies columns 2 and 3 */
  grid-row: 1 / 3; /* Occupies rows 1 and 2 */
  color: white;
  justify-content: center;
  font-weight: bold;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  justify-content: left;
}

.item:nth-child(3) {
  grid-column: 1 / 4; /* Occupies all 3 columns */
  grid-row: 3 / 4; /* Occupies row 3 */
  background-color: red; /* Blue */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-top: 18px;
}

// For the other cards

.dashboard-card {
  border-width: 2px 4px 6px 4px;
  border-style: solid;
  border-color: #e1e8ed;
  border-radius: 10.48px;
  padding: 18px;

  font-family: Poppins;

  .card-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .card-buttons {
    // Generic styling for the buttons
    display: flex;
    justify-content: center;
    font-family: "Poppins";
    align-items: center;
    cursor: pointer;

    //
    height: 41.92px;
    border-radius: 10.48px;
    font-weight: 500;

    //
    background: #ffffff;
    color: #169fed;
    border: 1.74667px solid #169fed;

    // full width
    width: 100%;
  }
}

.schedule-card {
  border-radius: 12px;
  border: 2px;
  border: 2px solid #e1e8ed;
  display: flex;
  padding: 24px;
  font-family: Poppins, "sans-serif";
  background-color: #ffffff;
  .schedule-title {
    margin: 0 0 8px 0;
    font-weight: 18px;
    font-weight: 500;
  }
  .inline-text {
    margin: 0;
  }
}

.blog-card {
  padding: 24px;
  font-family: Poppins, "sans-serif";
  background-color: #ffffff;
  cursor: pointer;

  .blog-container {
    display: flex;
    margin-bottom: 20px;
  }
  .blog-title {
    font-weight: 400;
    margin-bottom: 30px;
  }

  .blog-text-date {
    width: 58%;
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 5px 0 10px 20px;
  }
  .blog-image-container {
    height: 80px;
    width: 80px;
    border-radius: 20px;
  }
  .blog-card-image {
    height: 100%;
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
  }
  .blog-card-title {
    font-weight: 500px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .blog-date {
    font-weight: 400px;
    font-size: 12px;
  }
  .blog-see-more {
    display: flex;
    align-self: flex-end;
    justify-self: flex-end;
    margin-top: 30px;
    background-color: #169fed;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    font-weight: 600;
    cursor: pointer;
    max-width: 120px;
  }
}

.space {
  height: 24px;
}

@media (min-width: 1024px) {
  .home-section {
    display: flex;
    flex-direction: row;
    gap: 60px;
  }
  .dashboard-interactive-card,
  .dashboard-card,
  .schedule-card {
    // width: 510px;
  }
  .dashboard-interactive-card {
    height: 272px;
  }

  .blog-card {
    padding: 24px;
    font-family: Poppins, "sans-serif";
    background-color: #ffffff;

    .blog-container {
      display: flex;
    }
    .blog-title {
      font-weight: 400;
      margin-bottom: 30px;
    }
    .blog-image-container {
      background-color: gray;
      height: 100px;
      width: 100px;
      border-radius: 30px;
    }
    .blog-text-date {
      width: 58%;
      padding-left: 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 10px 0 10px 20px;
    }
    .blog-card-title {
      font-size: 16px;
    }
    .blog-date {
      // margin-top: 25px;
    }
  }
}
